import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const Business = () => {
    let { id } = useParams();  // Destructure id from useParams

    let getMobileOperatingSystem = () => {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;

        if (/windows phone/i.test(userAgent)) {
            alert("Unsupported OS");
        } else if (/android/i.test(userAgent)) {
            window.location.replace(`https://play.google.com/store/apps/details?id=com.techubllc.rezerve`);
        } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            window.location.replace("https://apps.apple.com/app/rezerve/id6496685025");
        } else {
            // Assuming open_web is some condition you check in your state or props
            const openWeb = true; // Replace this with the actual condition
            if (openWeb) {
                let url = `https://rezerve.pl/`;
                window.location.replace(url);
            }
        }
    }

    useEffect(() => {
        getMobileOperatingSystem();
    }, []);

    return (
        <>
            {/* Your component content goes here if any */}
        </>
    );
};

export default Business;
