import './App.css';
//
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import i18next from 'i18next';
// import CommingSoon from './components/Home/CommingSoon';
import PrivacyPolicyEn from './pages/PrivacyPolicyEn';
import PrivacyPolicyPl from './pages/PrivacyPolicyPl';
import TermsConditionEn from './pages/TermsConditionEn';
import TermsConditionPl from './pages/TermsConditionPl';
import CookiePoolicyEn from './pages/CookiesPolicyEn';
import CookiePoolicyPl from './pages/CookiesPolicyPl';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  createRoutesFromElements
} from "react-router-dom";
import AppleInfo from './components/JsonData/AppleInfo';
import Home from './pages/Home';
import BusinessManu from './pages/BusinessManu';
import Business from './pages/Business';
function App() {

  const lng = useSelector((state) => state?.resize?.language)


  useEffect(() => {
    if (lng) {
      i18next.changeLanguage(lng)
    }
  }, [])

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/">
          <Route index element={<Home />} />
          <Route path='/legal/privacy-policy/en' element={<PrivacyPolicyEn />} />
          <Route path='/legal/privacy-policy/pl' element={<PrivacyPolicyPl />} />
          <Route path='/legal/terms-and-conditions/en' element={<TermsConditionEn />} />
          <Route path='/legal/terms-and-conditions/pl' element={<TermsConditionPl />} />
          <Route path='/legal/cookie-policy/en' element={<CookiePoolicyEn />} />
          <Route path='/legal/cookie-policy/pl' element={<CookiePoolicyPl />} />
          <Route path='/app/business-menu' element={<BusinessManu />} />
          <Route path='/app/business/:id' element={<Business />} />
          <Route path='/app/subscriptions' element={<BusinessManu />} />
          <Route path='/app/boost' element={<BusinessManu />} />
          <Route path='/app/staff' element={<BusinessManu />} />
          <Route path='/app/branding' element={<BusinessManu />} />
          <Route path="/.well-known/apple-app-site-association" element={<AppleInfo />} />
        </Route>
      </>
    ))



  return (
    <RouterProvider router={router} />

  );
}

export default App;
